@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Lora:ital@1&display=swap');

$primary: #697a53;

$body-color: #2b211e;
$spacer: 2em;

$headings-font-family: 'Lora', serif;
$font-family-base: 'Montserrat', sans-serif;
$display-font-sizes: (
    1: 7rem,
    2: 6rem,
    3: 5rem,
    4: 4rem,
    5: 3rem,
    6: 2.5rem
);
