@import "variables";

@import "~bootstrap/scss/bootstrap";

.invert {
    filter: invert(1);
}

.btn {
    text-transform: uppercase;
}

#hero {
    img {
        max-height: 75vh;
        width: auto;
        max-width: 80%;
    }
}

.bg-leaves {
    background-image: url("./../img/watercolor-leaves.jpeg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-paper {
    background-image: url("./../img/white-paper-texture-background.jpeg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-music {
    background-image: url("./../img/disco-ball.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
